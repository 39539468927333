import React from "react";
import {Helmet} from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HeroVideo from "../components/GeneralComponents/HeroVideo";

const TrabalheConoscoPage = ({data}) => {
    return(
        <>
            <Helmet>
                <html lang="pt-BR" amp/>
                <meta charSet="UTF-8"/>
                <title>Cia. Ibérica - Trabalhe Conosco</title>
                <link rel="canonical" href="https://www.ciaiberica.com.br/trabalhe-conosco/"/>
                <meta name="robots" content="index,follow,max-snippet: -1,max-image-preview:large"/>
                <meta property="og:locale" content="pt-BR"/>
                <meta property="og:title" content="Cia. Ibérica - Trabalhe Conosco"/>
                <meta property="og:description"
                      content="Venha fazer parte do time Cia. Ibérica, uma empresa brasileira e com tradição no mercado."/>
                <meta property="og:url" content="https://www.ciaiberica.com.br/trabalhe-conosco/"/>
                <meta property="og:sitename" content="Cia. Ibérica"/>
                <meta property="article:publisher" content="https://facebook.com/ibericacondutoreseletricosltda"/>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
            </Helmet>
            <Header />
            <Footer />
        </>
    )
}

export default TrabalheConoscoPage